import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import $ from 'jquery';
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper";

const SidebarContent = props => {
  const ref = useRef()
  const [menuType, setMenu] = useState([])

  const segments = document.URL;
  const currentLink = segments.substr(segments.lastIndexOf('/') + 1);


  const [showAmazon, setShowAmazon] = useState(false)
  const [showEbay, setShowEbay] = useState(false)
  const [showWalmart, setShowWalmart] = useState(false)
  const [showWish, setShowWish] = useState(false)
  function setStoreLinks(){
    var stores = JSON.parse(localStorage.getItem('authUser')).store
    for(var i = 0; i < stores.length; i++){
        var name = stores[i].name;
        if(name === 'Wish.com') { setShowWish(true); } else 
            if(name === 'Amazon.com') { setShowAmazon(true); } else 
                if(name === 'Ebay.com') { setShowEbay(true); } else 
                    if(name === 'Walmart.com') { setShowWalmart(true); }
    }
  }


  useEffect(() => {
    setMenu([])
    setStoreLinks() 
  }, [])


  $(function() {
    $('.ul-li').removeClass("mm-show");
    $('.nav-left').removeClass("mm-active");
    $('.sub-menu').removeClass("mm-show");
    $('#menu-' + currentLink).addClass("mm-active");
    $('.ul-' + currentLink).addClass("mm-show");
    $('.ul-li-' + currentLink).addClass("mm-active");
    if(currentLink == 'user-totals' ||  currentLink == 'manage-users' || currentLink == 'referrals'){
      $('#menu-users').addClass("mm-active");
    }
    
    var m_type = window.localStorage.getItem('m_type');
    if(m_type == '1') {
      $('#menu-users').show();
      $('.menu-1').show();
    } else if(m_type == '2') {
      $('#menu-users').html(''); 
      $('.menu-2').show();
    } else {
      $('.menu-2').show();
    }
  })

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  },[]);  

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    new MetisMenu("#side-menu")
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

  }, [props.location.pathname,activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const pageRef = (e) => {
    
    if ($(window).width() < 768) { 
      console.clear();
      window.location.href=e;
    }

  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref} className="sidebar-menu-scroll">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
 
            <li className="menu-title">{props.t("Menu")} </li>            

            <li className="nav-left menu-2" style={{ display: "none" }} id="menu-dashboard">
              <Link to="/dashboard" className=" waves-effect mashood" onClick={(e) => { pageRef("/dashboard") }}>
                <i className="uil-analytics"></i>
                <span>{props.t("Account Analytics")}</span>
              </Link>
            </li>

            <li id="menu-users" className="menu-1" style={{ display: "none" }}>
              <Link to="/dashboard/user-stats" className="has-arrow waves-effect" onClick={(e) => { pageRef("/dashboard/user-stats") }}>
                <i className="uil-user-exclamation"></i>
                <span>{props.t("Users Information")}</span>
              </Link>
              <ul className="sub-menu ul-user-totals ul-manage-users ul-referrals">
                <li id="menu-user-totals">
                  <Link to="/dashboard/user-totals" onClick={(e) => { pageRef("/dashboard/user-totals") }}>{props.t("View Stats")}</Link>
                </li>
                <li id="menu-manage-users">
                  <Link to="/dashboard/manage-users" onClick={(e) => { pageRef("/dashboard/manage-users") }}>{props.t("Manage Users")}</Link>
                </li>
                <li id="menu-referrals">
                  <Link to="/dashboard/referrals" onClick={(e) => { pageRef("/dashboard/referrals") }}>{props.t("Referral Data")}</Link>
                </li>
              </ul>

              <li id="menu-promocode">
                <Link to="/dashboard/promocode" onClick={(e) => { pageRef("/dashboard/promocode") }}>
                <i className="fas fa-tools"></i>
                {props.t("Manage Promo Code")}</Link>
              </li>

              <li id="menu-cms">
                <a href="https://koloden.com/v2api/public/api/cms/home">
                <i className="fab fa-hornbill"></i>
                  {props.t("CMS")}</a>
              </li>
              
            </li>


            { showAmazon || showEbay || showWalmart || showWish ?
            <li className="menu-2" style={{ display: "none" }}>
              <Link to="/dashboard" className="has-arrow waves-effect">
                <i className="uil-files-landscapes"></i>
                <span>{props.t("Platforms")}</span>
              </Link>
              <ul className="sub-menu ul-amazon ul-ebay ul-walmart ul-wish">
                {showAmazon ? 
                <li className="nav-left" id="menu-amazon">
                  <Link to="/amazon" onClick={(e) => { pageRef("/amazon") }}> {props.t("Amazon")} </Link>
                </li>
                : <></>}

                {showEbay ?
                <li className="nav-left" id="menu-ebay">
                  <Link to="/ebay" onClick={(e) => { pageRef("/ebay") }}> {props.t("eBay")} </Link>
                </li>
                : <></>}

                {showWalmart ?
                <li className="nav-left" id="menu-walmart">
                  <Link to="/walmart" onClick={(e) => { pageRef("/walmart") }}>{props.t("Walmart")}</Link>
                </li>
                : <></>}

                {/* {showWish ?
                <li className="nav-left" id="menu-wish">
                  <Link to="/wish">{props.t("Wish")}</Link>
                </li>
                : <></>} */}
              </ul>
            </li>
            : <></>}
                
            <li className="nav-left menu-2" style={{ display: "none" }} id="menu-add-store">
              <Link to="/add-store-sp" className=" waves-effect" onClick={(e) => { pageRef("/add-store-sp") }} >
                <i className="uil-store"></i>
                <span>{props.t("Add Stores")}</span>
              </Link>
            </li>

            <li className="nav-left menu-2" style={{ display: "none" }} id="menu-tester">
              <Link to="/tester" className=" waves-effect" onClick={(e) => { pageRef("/tester") }} >
                <i className="uil-pen"></i>
                <span>{props.t("Tester")}</span>
              </Link>
            </li>

            <li className="nav-left menu-2" style={{ display: "none" }} id="menu-riskfeed">
              <Link to="/riskfeed" className=" waves-effect" onClick={(e) => { pageRef("/riskfeed") }} >
                <i className="uil-triangle"></i>
                <span>{props.t("Risk Feed")}</span>
              </Link>
            </li>
                
            <li className="nav-left menu-2" style={{ display: "none" }} id="menu-billing">
              <Link to="/billing" className=" waves-effect" onClick={(e) => { pageRef("/billing") }} >
                <i className="uil-money-bill"></i>
                <span>{props.t("Billing")}</span>
              </Link>
            </li>          
                
            <li class="ul-li ul-li-payment-method ul-li-editaccount menu-2" style={{ display: "none" }}>
              <Link to="/" className="has-arrow waves-effect">
                <i className="uil-user"></i>
                <span>{props.t("User Account")}</span>
              </Link>
              <ul className="sub-menu ul-editaccount ul-payment-method ul-cancel-subscription ul-notifications">
                <li className="nav-left" id="menu-editaccount">
                  <Link to="/editaccount" onClick={(e) => { pageRef("/editaccount") }} >{props.t("Edit Account")}</Link>
                </li>
                <li className="nav-left" id="menu-payment-method">
                  <Link to="/payment-method" onClick={(e) => { pageRef("/payment-method") }}>
                    {props.t("Payment Method")}
                  </Link>
                </li>
                <li className="nav-left" id="menu-cancel-subscription">
                  <Link to="/cancel-subscription" onClick={(e) => { pageRef("/cancel-subscription") }}>
                    {props.t("Cancel Subscription")}
                  </Link>
                </li> 
                <li className="nav-left" id="menu-notifications">
                  <Link to="/notifications" onClick={(e) => { pageRef("/notifications") }}>
                    {props.t("Notifications")}
                  </Link>
                </li> 
              </ul>
            </li>                       
                
            <li className="nav-left menu-2 show-onMobile" id="menu-billing">
              <Link to="/logout" className=" waves-effect" onClick={(e) => { pageRef("/logout") }}>
                <i className="uil-signout"></i>
                <span>{props.t("Logout")}</span>
              </Link>
            </li>    
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))