import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  Alert,
  CardTitle,
  Form,
  Container,
  Label,
  Input,
  FormGroup,
  Button
} from "reactstrap" 
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import './../../kolodenTheme/css/riskfeed.css'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb" 
import imgStore from './img-1.png';


const RiskFeed = props => {
  const [activeTab, setActiveTab] = useState(0); 
   
  const toggleNested = (event) => {
    let dataNestId = event.currentTarget.dataset.id;
    let classChecker = document.querySelector('.line-detail-'+dataNestId).classList;
    
    if(classChecker.contains("show-expand")){
      document.querySelector('.line-detail-'+dataNestId).classList.remove('show-expand');
      event.currentTarget.childNodes[0].classList.add('expand');
      event.currentTarget.childNodes[0].classList.remove('shrink'); 
      
    } else {
      document.querySelector('.line-detail-'+dataNestId).classList.add('show-expand');
      event.currentTarget.childNodes[0].classList.remove('expand');
      event.currentTarget.childNodes[0].classList.add('shrink'); 
    } 
  };

  const copyToClipBoard = (event) => {  
    console.clear(); 
 
    var copyText = event.target.innerHTML;  
    navigator.clipboard.writeText(copyText);
    
    let tinyAlert = document.createElement('div');
    tinyAlert.classList.add('tinyAlert');
    tinyAlert.innerHTML = "URL copied to the Clipboard: " + copyText;
    document.body.appendChild(tinyAlert);

    setTimeout(()=>{
      document.querySelector('.tinyAlert').remove();
    },3000)
  }

    return (
      <React.Fragment>
        <div className="page-content riskfeed">
          <Container fluid={true}>
            <Breadcrumbs title="Menu" breadcrumbItem="Risk Feed" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody> 
                    <Row>
                      <Col lg={12} className="mt"> 
                        <div className="tabs-riskfeed">
                          
                          <ul>
                            <li className={activeTab == 0 ? "active" : ""} onClick={()=>{setActiveTab(0)}}><span className="key-prompt">21</span>Active Issues</li>
                            <li className={activeTab == 1 ? "active" : ""} onClick={()=>{setActiveTab(1)}}><span className="key-prompt">21</span>Ignored Issues</li>
                          </ul>

                          <div className="tab-panel">
                            { activeTab == 0 ? <> 
                              <div className="table-data-item"> 
                              
                                <table className="table table-light">
                                  <thead>
                                    <tr>
                                        <th scope="col">Word Mark Matches</th>
                                        <th scope="col">U.S. Reg. No.	</th>
                                        <th scope="col" className="col-3">Cited Reference Image</th>
                                        <th scope="col">Goods & Services</th>
                                        <th scope="col">Reason</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* Parent Table is this */}
                                    <tr className="line-id">
                                      <td>
                                        <b>Line</b>
                                        {/* The Data-ID should be dynamic and be same as used in nested table id */}
                                        <div className="lock-expand" onClick={toggleNested} data-id={2}>
                                          <span className="expand"> 
                                          </span> 
                                            <p>Affected Listings</p>
                                        </div> 
                                      
                                      </td>
                                      <td><a href="">02231ZE</a></td>
                                      <td>
                                        <img src={imgStore} />
                                      </td>
                                      <td>Sets</td>
                                      <td>Litigation</td>
                                      <td>
                                        <label className="container-kol-chkb">Ignore All
                                          <input type="checkbox" defaultChecked={true}></input>
                                          <span className="checkmark"></span>
                                        </label>
                                      </td>
                                    </tr>

                                    {/* Nested Table of above table id*/}
                                    {/* the number after line-detail- in classname is dynamic and same as above one */}
                                    <tr className="line-detail-2 nested-table-area">
                                      <td colSpan={6}>
                                        <table className="listed-table text-center">
                                          <thead>
                                            <th scope="col">Platform / Store</th>
                                            <th scope="col">Listing Title</th>
                                            <th scope="col">Main Image</th>
                                            <th scope="col">URL</th>
                                            <th scope="col">Action</th>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>eBay / eBay Store</td>
                                              <td>2021 Live Stock</td>
                                              <td><img className="sub-img" src={imgStore}></img></td>
                                              <td>
                                                <span className="linklist" onClick={(e) => {copyToClipBoard(e)}}>www.google.com.pk</span>
                                              </td>
                                              <td className="text-left">
                                                <label className="container-kol-chkb">Ignore
                                                  <input type="checkbox" defaultChecked={true}></input>
                                                  <span className="checkmark"></span>
                                                </label>
                                                <button className="test">Test</button>
                                              </td>
                                            </tr> 
                                            <tr>
                                              <td>eBay / eBay Store</td>
                                              <td>2021 Live Stock</td>
                                              <td><img className="sub-img" src={imgStore}></img></td>
                                              <td>
                                                <span className="linklist" onClick={(e) => {copyToClipBoard(e)}}>www.bing.com</span>
                                              </td>
                                              <td className="text-left">
                                                <label className="container-kol-chkb">Ignore
                                                  <input type="checkbox" defaultChecked={true}></input>
                                                  <span className="checkmark"></span>
                                                </label>
                                                <button className="test">Test</button>
                                              </td>
                                            </tr> 
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
 

                                  </tbody>
                                </table>

                              </div>
                            </> : <>
                              <div className="li-gen-2">
                                This is content 2
                              </div>
                            </> }
                          </div>

                        </div>
                        

                      </Col>
                      
                    </Row> 

                    </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }

export default RiskFeed